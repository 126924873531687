import React from "react";

function Modal({children, isOpen, onClose}) {
    if (!isOpen) return null;
    return (
        <div className="modal-overlay">
            <div className="modal sm:w-[30%]">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                {children}
            </div>
        </div>
    )
}

export default Modal