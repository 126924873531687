import React from "react";
import Arrow from "../../Assets/Images/Icons/bar-left 1.png";
import Dashboardimg from "../../Assets/Images/Dashboard.png";

function FullStack() {
    return (
        <div>
            <div className="info-bg">
                <div className='line line-2'></div>
                <div className="w-[90%] mx-auto relative z-10">
                    <div className="sm:grid grid-cols-3">
                        <div className="">
                            <div className="text-center text-primaryColor bg-white font-bold text-2xl border-[3px] border-white sm:border-r-0 rounded-full sm:rounded-none sm:rounded-bl-full sm:rounded-tl-full py-3 sm:py-5">CCM</div>
                            <div className="flex justify-center">
                                <img src={Arrow} alt="img" />
                            </div>
                            <div className="border-[3px] border-white border-dashed p-5 rounded-lg mx-3">
                                <div className="border p-3 gradient-bg rounded-md mb-5">
                                    <div className="w-[100%]">
                                        <div className="text-base mb-2 text-white font-semibold ">Program For Patient Onboarding</div>
                                        <div className="text-sm font-normal text-white ">Introductory call, overview of the program, patient issues identification, communication
                                            optimization, and care team introduction.</div>
                                    </div>
                                </div>
                                <div className="border p-3 gradient-bg rounded-md ">
                                    <div className="w-[100%]">
                                        <div className="text-base mb-2 text-white font-semibold ">Partnered In Decision Maker</div>
                                        <div className="text-sm font-normal text-white ">Using the patient-provider relationship to your advantage: pinpoint urgent needs; find gaps in
                                            care; do functional evaluations; estimate the risks associated with social determinants; measure
                                            quality metrics; provide coaching and education.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="text-center text-primaryColor bg-white font-bold text-2xl border-[3px] border-white rounded-full sm:rounded-none sm:border-x-0 py-3 sm:py-5 mt-10 sm:mt-0">C-CCM</div>
                            <div className="flex justify-center">
                                <img src={Arrow} alt="img" />
                            </div>
                            <div className="border-[3px] border-white border-dashed p-5 rounded-lg mx-3">
                                <div className="border p-3 gradient-bg rounded-md mb-5">
                                    <div className="w-[100%]">
                                        <div className="text-base mb-2 text-white font-semibold ">Program For Patient Onboarding</div>
                                        <div className="text-sm font-normal text-white ">Introductory call, overview of the program, patient issues identification, communication
                                            optimization, and care team introduction.</div>
                                    </div>
                                </div>
                                <div className="border p-3 gradient-bg rounded-md ">
                                    <div className="w-[100%]">
                                        <div className="text-base mb-2 text-white font-semibold ">Partnered In Decision Maker</div>
                                        <div className="text-sm font-normal text-white ">Using the patient-provider relationship to your advantage: pinpoint urgent needs; find gaps in
                                            care; do functional evaluations; estimate the risks associated with social determinants; measure
                                            quality metrics; provide coaching and education.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="text-center text-primaryColor bg-white font-bold text-2xl border-[3px] border-white border-l-0 rounded-full sm:rounded-none sm:rounded-br-full sm:rounded-tr-full py-3 sm:py-5 mt-10 sm:mt-0">RPM</div>
                            <div className="flex justify-center">
                                <img src={Arrow} alt="img" />
                            </div>
                            <div className="border-[3px] border-white border-dashed p-5 rounded-lg mx-3">
                                <div className="border p-3 gradient-bg rounded-md mb-5">
                                    <div className="w-[100%]">
                                        <div className="text-base mb-2 text-white font-semibold ">Patient Onboarding</div>
                                        <div className="text-sm font-normal text-white ">VCarepro provides device configuration services, ensuring seamless integration into patient
                                            routines. Additionally, we offer comprehensive education to empower patients in utilizing the
                                            devices effectively for optimal health management.</div>
                                    </div>
                                </div>
                                <div className="border p-3 gradient-bg rounded-md ">
                                    <div className="w-[100%]">
                                        <div className="text-base mb-2 text-white font-semibold ">Data Exchange</div>
                                        <div className="text-sm font-normal text-white ">The care coordinator will help with physiological data evaluation and patient transports.
                                            Bluetooth or Wifi can be used to transport data.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='line line-3'></div>
            </div>
            <div className='line line-4'></div>
            <div className="my-[3rem] text-center bg-white z-10 relative  py-5">
                <div className="text-2xl sm:text-4xl font-bold pb-5">Resulting in Sustainable Growth</div>
                <div className="text-sm">End of month billing generated with
                    CPT & ICD10 codes</div>
            </div>
            <div className="relative">
            <div className="webimg-bg sm:w-[60%] relative z-10 mx-auto">
                <img src={Dashboardimg} alt="img" />
            </div>
            </div>
        </div>
    )
}

export default FullStack