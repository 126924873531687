import React from "react";
import whiteLogo from "../../Assets/Images/white-logo.png";
import Callicon from "../../Assets/Images/Icons/call.svg";
import Emailicon from "../../Assets/Images/Icons/email.svg";
import { Link } from "react-router-dom";
import footer1 from "../../Assets/Images/they-ask-you-answer-certified-business-blue.webp";
import footer2 from "../../Assets/Images/HIPAA-Accountable-grey.png";
import footer3 from "../../Assets/Images/HIPAA-Aptible.png";

function Footer() {
    return (
        <div>
            <div className="custom-boxshadow bg-primaryColor">
                <div className="container mx-auto py-[3rem]">
                    <div className="sm:flex justify-between">
                        <div className="sm:w-[30%]">
                            <img src={whiteLogo} alt="img" className="w-40" />
                            <div className="text-white pt-5 text-sm font-light">VCarepro pioneers healthcare advancement with cutting-edge CCM and RPM software, coupled with an intuitive mobile application. Elevate patient care, streamline workflows, and embrace the future of personalized, efficient healthcare solutions. </div>
                            <div className="flex items-center gap-5 mt-4">
                                <img src={footer1} alt="img" width={80} />
                                <img src={footer2} alt="img" className="brightness-[5.5]" width={80} />
                                <img src={footer3} alt="img" width={80} />

                            </div>
                        </div>
                        {/* <div className="">
                        <div className="text-lg pb-2 font-medium text-white">Contact Us</div>
                        </div> */}
                        <div className="mt-5 sm:mt-0">
                            <div className="text-lg pb-2 font-medium text-white">Contact Us</div>
                            <div className="text-white text-sm font-light mb-3 leading-loose">
                            #179, Kothari Nagar, Singanallur,<br /> Coimbatore - 641 005. INDIA
                            </div>
                            <a className="flex gap-3 items-center text-white mb-3 text-sm font-light" href="tel:+91 90256 33829"><div className=""><img src={Callicon} alt="img" /></div> +91 90256 33829</a>
                            <a className="flex gap-3 items-center text-white text-sm font-light" href="mailto:info@vcarepro.com"><div className=""><img src={Emailicon} alt="img" /></div> info@vcarepro.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#075674] py-2">
                <div className="container mx-auto">
                    <div className="flex justify-between items-center">
                        <div className=" text-xs sm:text-sm text-white ">Copyright © 2024 Cytozon | All Rights Reserved.</div>
                        <div className="flex items-center gap-3 text-white">
                            <Link to="/terms-conditions" className="text-xs sm:text-sm text-white cursor-pointer hover:underline">Terms & Conditions</Link>
                            |
                            <Link to="/privacy-policy" className="text-xs sm:text-sm text-white cursor-pointer hover:underline">Privacy & Policy</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer