import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Contact from './Components/Pages/Contact';
import Landing from './Components/Pages/Landing';
import TermsConditions from './Components/Pages/TermsConditions';
import Policy from './Components/Pages/Policy';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Landing} />
        {/* <Route path="/about" component={About} /> */}
        <Route path="/contact" component={Contact} />
        <Route path="/terms-conditions" component={TermsConditions} />
        <Route path="/privacy-policy" component={Policy} />
      </Switch>
    </Router>
  );
}

export default App;
