import React, { useEffect, useRef, useState } from 'react';
import Header from "../Shared/Header";
import bannerImg from "../../Assets/Images/banner.png";
import $ from 'jquery';
import Infographic from './Infographic';
import FullStack from './FullStack';
import Fullstackimg from "../../Assets/Images/Full-stack.png"
import Typed from 'typed.js';
import Footer from '../Shared/Footer';
import Consultation from './Consultations';
import Modal from '../Shared/Modal';

function Landing() {
    const oneRef = useRef(null);
    const twoRef = useRef(null);
    const threeRef = useRef(null);
    const fourRef = useRef(null);

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop >= oneRef.current.offsetTop) {
                document.body.style.backgroundColor = oneRef.current.dataset.color;
            }

            if (scrollTop > twoRef.current.offsetTop) {
                document.body.style.backgroundColor = twoRef.current.dataset.color;
            }

            if (scrollTop > threeRef.current.offsetTop) {
                document.body.style.backgroundColor = threeRef.current.dataset.color;
            }

            if (scrollTop > fourRef.current.offsetTop) {
                document.body.style.backgroundColor = fourRef.current.dataset.color;
            }

            // if (scrollTop > fiveRef.current.offsetTop) {
            //     document.body.style.backgroundColor = fiveRef.current.dataset.color;
            // }
        };

        const typing = new Typed('.typingtext', {
            strings: [
                '',
                'optimize patient involvement',
                'team collaboration',
                'workflow efficiency',
                'foster revenue expansion',
            ],
            typeSpeed: 100,
            backSpeed: 40,
            loop: true,
        });

        $(window).on('scroll touchmove', handleScroll);

        return () => {
            $(window).off('scroll touchmove', handleScroll);
            typing.destroy();
        };
    }, []);

    return (
        <div>
            <section ref={oneRef} data-color="#fff">
                <Header />
                <div className="container mx-auto min-h-[80vh] flex pt-[8rem] sm:pt-0">
                    <div className="block sm:flex justify-between items-center">
                        <div className="w-full sm:w-8/12 sm:pr-[4rem]">
                            <div className="text-3xl sm:text-[2.7rem] font-black mb-5 leading-snug ">Physician-driven CCM, C-CCM & RPM <span className='typingtext gradient-color capitalize'></span></div>

                            <div className="text-textPara text-base sm:text-lg leading-loose mb-8">Through full-service, sustainable Chronic and Complex Chronic Care Management
                                (CCM) (C-CCM) and doctor-supported Remote Patient Monitoring (RPM), Vcarepro
                                enhances clinical care and promotes practice growth.</div>
                            <div onClick={openModal} className="bg-gradient-to-r from-primaryColor to-secondaryColor py-3 px-9 rounded-md text-white text-base cursor-pointer w-fit">Schedule a Demo</div>
                        </div>
                        <div className="w-full mt-[3rem] sm:mt-0 mb-[3rem] sm:mb-0 sm:w-4/12">
                            <img src={bannerImg} alt="img" />
                        </div>
                    </div>
                </div>
            </section>
            <section ref={twoRef} data-color="#fff">
                <div className="container mx-auto ">
                    <div className="text-2xl sm:text-4xl text-center capitalize sm:w-[70%]  mx-auto font-medium mb-7 sm:mb-14 leading-snug">
                        Our vision is to make healthcare efficient, effective, & affordable!
                    </div>
                    <div className="text-textPara text-sm sm:text-lg leading-loose mb-8">At VCarepro, we envision a future where healthcare is seamlessly connected, personalized, and accessible
                        to all. Our mission is to pioneer innovative CCM (Chronic Care Management), (C-CCM) Complex Chronic Care Management and RPM (Remote Patient Monitoring) software
                        solutions that empower healthcare providers to deliver unparalleled patient care. By harnessing the power of technology, we strive to create a healthcare ecosystem that is not just reactive but proactive, where patients are actively engaged in their well-being, and providers have the tools they need to deliver personalized, efficient, and compassionate care. Join us in shaping a healthier world.</div>
                    <div className="text-primaryColor font-bold text-sm sm:text-lg leading-loose mb-8 text-center sm:w-[60%] mx-auto">“Where cutting-edge technology meets compassionate healthcare, making a positive impact on the lives of individuals and communities globally."
                    </div>
                    <div className='sm:w-[80%] bg-[#e8f9ff] p-[3rem] rounded-3xl  my-14 sm:mx-auto'>
                        <img src={Fullstackimg} alt='img' />
                    </div>

                </div>
            </section>
            <section ref={threeRef} className='sm:py-[70px] ' data-color="#e9faff">
                <div className="container mx-auto">
                    <div className="text-2xl sm:text-4xl capitalize text-center font-medium sm:mb-5 leading-snug">
                        Our Proven Patient Care Methodology <br></br> (CCM, C-CCM & RPM)
                    </div>
                    <div className='pcm-bg'>
                        <Infographic />
                    </div>
                </div>
            </section>
            <div ref={fourRef} data-color="#fff" className='pb-[3rem]'>
                <FullStack />
            </div>
            <Footer />
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Consultation />
            </Modal>
        </div >
    )
}

export default Landing