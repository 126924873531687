import React, { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Consultation() {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    return (
        <>
            <div className="border-b  mb-5">
                <div className="text-xl sm:text-2xl capitalize font-medium mb-3">Get <span className="gradient-color">Free</span> Consultation</div>
            </div>
            <form>
                <div className="mb-5">
                    <label className="w-full inline-block text-sm mb-3">Date <span className="text-red-500">*</span></label>
                    <div className="w-full">
                        <DatePicker
                            className="w-full border text-sm font-normal rounded py-3 cursor-pointer text-textPara px-3"
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="Select a date"
                        />
                    </div>
                </div>
                <div className="mb-5">
                    <label className="w-full inline-block text-sm mb-3">Full Name <span className="text-red-500">*</span></label>
                    <input type="text" className="w-full border rounded py-3 cursor-pointer text-textPara px-3" />
                </div>
                <div className="mb-5">
                    <label className="w-full inline-block text-sm mb-3">Email <span className="text-red-500">*</span></label>
                    <input type="text" className="w-full border rounded py-3 cursor-pointer text-textPara px-3" />
                </div>
                <div className="mb-10">
                    <label className="w-full inline-block text-sm mb-3">Phone Number <span className="text-red-500">*</span></label>
                    <input type="text" className="w-full border rounded py-3 cursor-pointer text-textPara px-3" />
                </div>
                <div className="bg-primaryColor py-2 px-12 mb-5 rounded-md text-white w-fit text-base cursor-pointer">Get Free Trial</div>
            </form>
        </>
    )
}

export default Consultation