import React, { useState } from "react";
import Logo from "../../Assets/Images/logo.png"
import { Link } from "react-router-dom";
import Modal from "./Modal";
import Consultation from "../Pages/Consultations";

function Header() {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    return (
        <div className="bg-white custom-boxshadow fixed top-0 w-full z-50">
            <div className="container mx-auto">
                <div className="flex justify-between items-center  h-20">
                    <div>
                        <Link to="/">
                            <img src={Logo} alt="img" className="sm:w-40 w-28" />
                        </Link>
                    </div>
                    <ul className="sm:flex gap-8 hidden ">
                        <li className="cursor-pointer hover:text-secondaryColor hover:font-medium"><Link to="/">Home</Link></li>
                        <li className="cursor-pointer hover:text-secondaryColor hover:font-medium">About</li>
                        <li className="cursor-pointer hover:text-secondaryColor hover:font-medium"><Link to="/contact">Contact</Link></li>
                    </ul>
                    <div className="flex gap-3">
                        <div onClick={openModal} className="hidden sm:block bg-white border-primaryColor border py-1 px-5 rounded-md text-primaryColor text-base cursor-pointer">Schedule a Demo</div>
                        <div className="bg-primaryColor py-1 px-5 rounded-md text-white text-base cursor-pointer hidden sm:block">Login</div>
                        <div className="block sm:hidden bg-primaryColor py-1 px-5 rounded-md text-white text-base cursor-pointer"><Link to="/contact">Contact</Link></div>
                    </div>
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Consultation />
            </Modal>
        </div>
    )
}

export default Header