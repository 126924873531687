import React, { useState } from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import ContactImage from "../../Assets/Images/Contactus-amico.svg"

function Contact() {
    const [selectedOption, setSelectedOption] = useState('Option 1');
    const options = ['--Please Select Field--', 'Business Inquiry', 'Others'];

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    return (
        <div>
            <div>
                <Header />
                <div className="container mx-auto pt-[10rem] pb-[5rem]">
                    <div className="">
                        <div className="text-2xl sm:text-4xl capitalize font-medium mb-3">Let's Talk <span className="gradient-color">About Your Needs</span></div>
                        <div className="text-textPara text-sm sm:text-lg leading-loose mb-8">Thank you for interest in VCarepro services</div>
                    </div>
                    <div className="sm:flex gap-10">
                        <div className="sm:w-[55%]">
                            <div className="bg-white p-5 shadow-lg border border-[#f0f0f0] rounded-xl">
                                <form>
                                    <div className="mb-5">
                                        <label className="w-full inline-block text-sm mb-3">Inquiry Type <span className="text-red-500">*</span></label>
                                        <select
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                            className="w-full border rounded py-3 cursor-pointer text-textPara px-3"
                                        >
                                            {options.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-5">
                                        <label className="w-full inline-block text-sm mb-3">Full Name <span className="text-red-500">*</span></label>
                                        <input type="text" className="w-full border rounded py-3 cursor-pointer text-textPara px-3" />
                                    </div>
                                    <div className="mb-5">
                                        <label className="w-full inline-block text-sm mb-3">Email <span className="text-red-500">*</span></label>
                                        <input type="text" className="w-full border rounded py-3 cursor-pointer text-textPara px-3" />
                                    </div>
                                    <div className="mb-5">
                                        <label className="w-full inline-block text-sm mb-3">Phone Number <span className="text-red-500">*</span></label>
                                        <input type="text" className="w-full border rounded py-3 cursor-pointer text-textPara px-3" />
                                    </div>
                                    <div className="mb-5">
                                        <label className="w-full inline-block text-sm mb-3">Message <span className="text-red-500"></span></label>
                                        <textarea type="text" className="w-full border rounded py-3 cursor-pointer text-textPara px-3" />
                                    </div>
                                    <div className="mb-10">
                                        <label className="flex items-center gap-3 mb-1">
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                           <div className="text-sm"> I have read and accepted the <span className="underline text-primaryColor cursor-pointer">Terms of Use</span> and <span className="underline text-primaryColor cursor-pointer">Privacy Policy</span>.</div>
                                        </label>
                                        {!isChecked && (
                                            <div className="text-red-500 text-sm">Please complete this required field.</div>
                                        )}
                                    </div>
                                    <div className="bg-primaryColor py-2 px-7 rounded-md text-white w-fit text-base cursor-pointer">Send Message</div>
                                </form>
                            </div>
                        </div>
                        <div className="hidden sm:block w-[45%] flex justify-center items-center">
                            <img src={ContactImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact;