import React from "react";
import HospitalIcon from "../../Assets/Images/Icons/hospital1.png"
import Logo from "../../Assets/Images/logo2.png"


function Infographic() {
    return (
        <div className="mt-[50px] sm:mt-[100px]">
            <div className='line line-0'></div>

            <div className="flex justify-center">
                <div className="circleAnim flex justify-center items-center">
                    <img src={HospitalIcon} alt="icons" width={40} />
                </div>
            </div>
            <div className="sm:grid grid-cols-2 items-center my-[5rem] relative z-10">
                <div className="">
                    <div className="p-5 bg-[#feeadd] border-b-[4px] border-[#E96F00] text-white sm:w-[90%] rounded-3xl text-left mb-5 ">
                        <div className="capitalize text-xl text-[#E96F00] mb-3">Chronic Care Management</div>
                        <div className="font-normal text-sm text-[#283548]">With VCarepro's turn-key Chronic Care Management (CCM) solution, patient care is effectively managed while 
                        adhering to your guidelines and evidence-based procedures. A steady and recognizable voice is comforting to patients and improves support in between clinic 
                        appointments. Examine our CCM services to learn about the methodical and successful technique. </div>
                    </div>
                    <div className="p-5 bg-[#f3e7ff] border-b-[4px] border-[#6B02C2] text-white sm:w-[90%] rounded-3xl text-left mb-5 sm:mb-0">
                        <div className="capitalize text-xl text-[#6B02C2] mb-3">Complex - Chronic Care Management</div>
                        <div className="font-normal text-sm text-neutral-900">Complex Chronic Care Management (C-CCM) solution, we elevate patient care by meticulously adhering 
                        to your guidelines and evidence-based procedures. Providing a steadfast and recognizable voice, our solution brings comfort to patients and enhances support 
                        between clinic appointments. Explore our C-CCM services to discover a systematic and effective approach to managing complex chronic conditions. Your patients 
                        deserve the best, and we deliver excellence in care coordination and support.</div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <div className="p-5 bg-[#c5f0fd] border-b-[4px] border-primaryColor text-white sm:w-[90%] rounded-3xl text-left mb-5">
                        <div className="capitalize text-xl text-primaryColor mb-3">Remote Patient Monitoring</div>
                        <div className="font-normal text-sm text-[#283548]">VCarepro's Remote Patient Monitoring offers healthcare practitioners a comprehensive service,
                            including in-depth physiological data analysis and personalized patient management. The
                            platform enables informed decision-making through robust data analytics, fostering a proactive
                            and engaged healthcare environment. With a high-touch approach, practitioners can establish
                            continuous connections with patients, providing ongoing support and personalized care,
                            ultimately improving patient outcomes. </div>
                    </div>
                </div>
            </div>
            <div className='line line-1'></div>
            <div className="flex justify-center">
                <div className="circleAnim cirleAnim2 flex justify-center items-center">
                    <img src={Logo} alt="icons" width={100} />
                </div>
            </div>
            <div className="my-[5rem] flex justify-center relative z-index">
                <div className="text-lg text-white font-bold w-fit bg-gradient-to-r from-primaryColor to-secondaryColor py-3  rounded-md px-5">ENROLLMENT PROCESS</div>
            </div>
        </div>
    )
}

export default Infographic