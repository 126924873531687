import React from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

function Policy() {
    return (
        <div>
            <Header />
            <div className="pt-[5rem]">
                <div className="termsBg py-[4rem]">
                    <div className="container mx-auto">
                        <div className=" text-center text-white text-baseline mb-5 font-medium">TERMS OF USE</div>
                        <div className=" text-center text-white text-5xl mb-8 font-semibold">VcarePro's Terms of Use</div>
                    </div>
                </div>
                <div className="py-[3rem]">
                    <div className="container mx-auto">
                        <div className="text-2xl mb-4 font-semibold">1. Introductions</div>
                        <p className="leading-loose text-textPara mb-3">This privacy policy (“Privacy Policy”) of VCarePro, Inc. (“VCarePro” or “Us” or “We”) sets forth the terms applicable to you (“You” or “Your”) and describes how we collect, use, process, and disclose your personal information, in conjunction with your access to and use of the VCarePro Platform including https://www.VCarePro.com/ (the “Website”) and any application(s), including updates, available on the Website (the “Application”) and also the terms of service that apply to any of the services accessible through any such Application (collectively, the “Services”), plus any information we collect in our capacity as a Business Associate to your health care provider, which is a Covered Entity under the Health Insurance Portability and Accountability Act (“HIPAA”). Your trust is important to us and we’re committed to protecting the privacy and security of your personal information.
                        </p>
                        <div className="text-2xl mb-4 font-semibold">2. Information We Collect </div>
                        <p className="leading-loose text-textPara mb-3">There are three general categories of information we collect.</p>
                        <div className="text-xl mb-4 font-semibold">2.1. Information You Give to Us </div>
                        <p className="leading-loose text-textPara mb-3">2.1.1 Information that is necessary for the use of the VCarePro Platform. We ask for and collect the following personal information about you
                            when you use the VCarePro Platform. This information is necessary for the adequate performance of the contract between you and us and to allow us to comply with our legal obligations. Without it, we may not be able to provide you with all the requested services.</p>
                        <ul className="pl-5 list-disc">
                            <li className="leading-loose text-textPara mb-3">Account Information. When you sign up for a VCarePro Account, we require certain information such as your first name, last name, email address, and date of birth.</li>
                            <li className="leading-loose text-textPara mb-3">Profile and Offering Information. To use certain features of the VCarePro Platform (such as creating an Offering), we may ask you to provide additional information, which may include your address, phone number, and a profile picture.</li>
                            <li className="leading-loose text-textPara mb-3">Identity Verification Information. To help create and maintain a trusted environment, we may collect identity verification information (such as images of your government issued ID, passport, national ID card, or driving license, as permitted by applicable laws) or other authentication information.</li>
                            <li className="leading-loose text-textPara mb-3">Payment Information. To use certain features of the VCarePro Platform (such as creating an Offering), we may require you to provide certain financial information (like your bank account or credit card information) in order to facilitate the processing of payments (via VCarePro Payments).</li>
                            <li className="leading-loose text-textPara mb-3">Communications with VCarePro and other Users. When you communicate with VCarePro or use the VCarePro Platform to communicate with other Users, we collect information about your communication and any information you choose to provide.</li>
                        </ul>
                        <p className="leading-loose text-textPara mb-3">2.1.2 Information You Give to Us. You may choose to provide us with additional personal information in order to obtain a better user experience when using VCarePro Platform. This additional information will be processed based on our legitimate interest or when applicable, your consent.</p>
                        <p className="leading-loose text-textPara mb-3">2.1.3 Information We Automatically Collect from Your Use of the VCarePro Platform and Services. When you use the VCarePro Platform, we automatically collect personal information about the services you use and how you use them. This information is necessary for the adequate performance of the contract between you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the VCarePro Platform and Payment Services.</p>
                        <ul className="pl-5 list-disc">
                            <li className="leading-loose text-textPara mb-3">Geo-location Information. When you use certain features of the VCarePro Platform, we may collect information about your precise or approximate location as determined through data such as your
                                IP address or mobile device’s GPS to offer you an improved user experience. Most mobile devices allow you to control or disable the use of location services for applications in the device’s settings menu. VCarePro may also collect this information even when you are not using the app if this connection is enabled through your settings or device permissions.</li>
                            <li className="leading-loose text-textPara mb-3">Usage Information. We collect information about your interactions with the VCarePro Platform such as the pages or content you view, your searches for Offerings, you have made, and other actions on the VCarePro Platform.</li>
                            <li className="leading-loose text-textPara mb-3">Log Data and Device Information. We automatically collect log data and device information when you access and use the VCarePro Platform, even if you have not created a VCarePro Account or logged in. That information includes, among other things: details about how you’ve used the VCarePro Platform (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the VCarePro Platform.</li>
                            <li className="leading-loose text-textPara mb-3">Cookies and Similar Technologies. We use cookies and other similar technologies when you use our platform, use our mobile
                                app, or engage with our online ads or email communications. We may collect certain information by automated means using technologies such as cookies, web beacons, pixels, browser analysis tools, server logs, and mobile identifiers. In many cases the information we collect using cookies and other tools is only used in a non-identifiable without reference to personal information. For example, we may use information we collect to better understand website traffic patterns and to optimize our website experience. In some cases, we associate the information we collect using cookies and other technology with your personal information. Our business partners may also use these tracking technologies on the VCarePro Platform or engage others to track your behavior on our behalf.</li>
                        </ul>
                        <p className="leading-loose text-textPara mb-3">2.1.4 Information We Collect from Third Parties. VCarePro may collect information, including personal information, that others provide about you when they use the VCarePro Platform, or obtain information from other sources and combine that with information we collect through the VCarePro Platform. We do not control, supervise or respond for how the third parties providing your information process your Personal Information, and any information request regarding the disclosure of your personal information to us should be directed to such third parties.</p>
                        <div className="text-xl mb-4 font-semibold">2.2 Children’s Data </div>
                        <p className="leading-loose text-textPara mb-3">Our websites and applications are not directed to children under 13 and we do not knowingly collect any personal information directly from children under 13. If you believe that we processing the personal information pertaining to a child inappropriately, we take this very seriously and urge you to contact us using the information provided under the “Contact Us” section below.</p>
                        <div className="text-2xl mb-4 font-semibold">3. HOW WE USE INFORMATION WE COLLECT</div>
                        <p className="leading-loose text-textPara mb-3">We may use, store, and process personal information to (1) provide, understand, improve, and develop the VCarePro Platform, (2) create and maintain a trusted and safer environment (such as to comply with our legal obligations and ensure compliance with VCarePro Policies) and (3) provide, personalize, measure, and improve our advertising and marketing.</p>
                        <div className="text-xl mb-4 font-semibold">3.1 Provide, Improve, and Develop the VCarePro Platform </div>
                        <p className="leading-loose text-textPara mb-3">We may use the personal information to provide, improve, and develop the VCarePro Platform. We process this personal information for these purposes given our legitimate interest in improving the VCarePro Platform and our Users’ experience with it, and where it is necessary for the adequate performance of the contract with you.</p>
                        <div className="text-xl mb-4 font-semibold">3.2 Create and Maintain a Trusted and Safer Environment</div>
                        <p className="leading-loose text-textPara mb-3">We may use the personal information to create and maintain a trusted and safer environment such as to:</p>
                        <ul className="pl-5 list-disc">
                            <li className="leading-loose text-textPara mb-3">detect and prevent fraud, spam, abuse, security incidents, and other harmful activity</li>
                            <li className="leading-loose text-textPara mb-3">conduct security investigations and risk assessments,</li>
                            <li className="leading-loose text-textPara mb-3">verify or authenticate information or identifications provided by you (such as to verify your Accommodation address or compare your identification photo to another photo you provide),</li>
                            <li className="leading-loose text-textPara mb-3">conduct checks against databases and other information sources, including background or police checks, to the extent permitted by applicable laws and with your consent where required,</li>
                            <li className="leading-loose text-textPara mb-3">comply with our legal obligations,</li>
                            <li className="leading-loose text-textPara mb-3">Resolve any disputes with any of our Users and enforce our agreements with third parties,</li>
                            <li className="leading-loose text-textPara mb-3">enforce our Terms of Service and other policies, and</li>
                            <li className="leading-loose text-textPara mb-3">in connection with the activities above, we may conduct profiling based on your interactions with the VCarePro Platform, your profile information and other content you submit to the VCarePro Platform, and information obtained from third parties. In limited cases, automated processes may restrict or suspend access to the
                                VCarePro Platform if such processes detect activity that we think poses a safety or other risk to the VCarePro Platform, our community, or third parties. If you challenge the decisioning based on the automated process, please contact us as provided in the Contact Us section below.</li>

                        </ul>
                        <p className="leading-loose text-textPara mb-3">We process this personal information for these purposes given our legitimate interest in protecting the VCarePro Platform, to measure the adequate performance of our contract with you, and to comply with applicable laws.</p>
                        <div className="text-xl mb-4 font-semibold">3.3 Provide, Personalize, Measure, and Improve our Advertising and Marketing</div>
                        <p className="leading-loose text-textPara mb-3">We may use the personal information to provide, personalize, measure, and improve our advertising and marketing .We will process your personal information for the purposes listed in this section given our legitimate interest in undertaking marketing activities to offer you products or services that may be of your interest.</p>
                        <div className="text-xl mb-4 font-semibold">3.4 SMS Terms for U.S</div>
                        <p className="leading-loose text-textPara mb-3">For text messaging in the United States, by requesting, joining, agreeing to, enrolling in, signing up for, acknowledging, or otherwise consenting to receive one or more text messages (“Opting In”) or using a VCarePro arrangement in which VCarePro sends (or indicates that it may send, or receives a request that
                            it send) one or more text messages (“Text Message Service”), you accept these SMS Terms for U.S. (“SMS Terms”), consent to the handling of your personal information as described in the VCarePro Privacy Policy, and agree to resolve disputes with VCarePro as described in our Terms of Service. Message and data rates may apply. VCarePro will use reasonable commercial efforts to deliver the automated marketing text messages to the number you provide through compatible wireless carriers. Carriers and VCarePro are not liable for delayed or undelivered messages. The short code we use for some Text Message Services may not be supported on all U.S. carriers.</p>
                        <p className="leading-loose text-textPara mb-3">By Opting In to a Text Message Service:</p>
                        <ul className="pl-5 list-disc">
                            <li className="leading-loose text-textPara mb-3">You expressly authorize VCarePro to use autodialer or non-autodialer technology to send text messages to the cell phone number associated with your Opt-In (i.e., the number listed on the Opt-In form or instructions, or, if none, the number from which you send the Opt-In, or, if none, the number on file for your account). You also authorize VCarePro to include marketing content in any such messages. You do not have to Opt In or agree to Opt In as a condition of purchase.</li>
                            <li className="leading-loose text-textPara mb-3">You consent to the use of an electronic record to document your Opt-In. To withdraw that consent, request a free paper or email copy of the Opt-In, or to update our records with your contact information, please contact us via the methods described in the Contact Us section. To view and retain an electronic copy of these SMS Terms or the rest of your Opt-In, you will need (i) a device (such as a computer or cell phone) with internet access, and (ii) either a printer or storage space on such device. If you withdraw your consent, certain features of our service may not be available to you. To update information on how we would contact you electronically, visit your Notification settings in your account settings.</li>
                            <li className="leading-loose text-textPara mb-3">You confirm that you are the subscriber to the relevant phone number or that you are the customary user of that number on a family or business plan and that you are authorized to Opt In.</li>
                            <li className="leading-loose text-textPara mb-3">You consent to the use of an electronic record to document your Opt-In. To withdraw that consent, update our records with your contact information, please visit your VCarePro account Notifications settings or contact customer support.</li>
                            <li className="leading-loose text-textPara mb-3">These SMS Terms still will apply if you withdraw the consent mentioned above or opt out of the Text Message Service.</li>
                        </ul>
                        <p className="leading-loose text-textPara mb-3">After Opting In, in addition to the main messages the service offers, you may receive one or more welcome messages or administrative messages, such as (in some cases) a request to confirm your Opt-In.</p>
                        <p className="leading-loose text-textPara mb-3">About the Text Message Services and Opting Out</p>
                        <p className="leading-loose text-textPara mb-3">Message and data rates may apply. Unless otherwise noted, Text Message Services send multiple, recurring messages. VCarePro may terminate any Text Message Service or your participation in it at any time with or without notice, including, for example, before you have received any or all messages that you otherwise would have received, but these SMS Terms still will apply. Text STOP to any promotional message to Opt Out or if applicable update your “Notification” settings on your VCarePro account.</p>
                        <p className="leading-loose text-textPara mb-3">For additional help, text HELP in response to a marketing message or contact customer service.</p>
                        <div className="text-xl mb-4 font-semibold">3.5 Your Choices</div>
                        <p className="leading-loose text-textPara mb-3">You have choices on the promotional messages that you choose to receive.</p>
                        <ul className="pl-5 list-disc">
                            <li className="leading-loose text-textPara mb-3">You can limit the information you provide to VCarePro. Participation</li>
                            <li className="leading-loose text-textPara mb-3">You can limit the communications that VCarePro sends to you.</li>
                            <ul className="pl-7 mt-3 list-decimal">
                                <li className="leading-loose text-textPara mb-3">To opt-out of marketing emails, simply click the link labeled “unsubscribe” at the bottom of any marketing email we send you or access the notification settings in your VCarePro Account.</li>
                                <li className="leading-loose text-textPara mb-3">To revoke permissions that you may have given to send promotional text messages, text STOP in response to any message.</li>
                                <li className="leading-loose text-textPara mb-3">You can always update your notification settings within your VCarePro Account by visiting the Notifications section of your VCarePro Account. Please note that even if you opt-out of marketing communications, we may still need to contact you with important transactional information about your account. For example, even if you opt-out of emails, we may still send you activity confirmations or fraud alerts.</li>
                            </ul>
                        </ul>
                        <div className="text-2xl mb-4 font-semibold">4. SHARING & DISCLOSURE</div>
                        <div className="text-xl mb-4 font-semibold">4.1 Sharing With Your Consent</div>
                        <p className="leading-loose text-textPara mb-3">Where you have provided consent, we share your information, including personal information, as described at the time of consent, such as when you authorize a third party application or website to access your VCarePro Account or when you participate in promotional activities conducted by VCarePro partners or third parties.</p>
                        <p className="leading-loose text-textPara mb-3">We will disclose your information when:</p>
                        <ul className="pl-5 list-disc">
                            <li className="leading-loose text-textPara mb-3">You have given us your consent to share or use information about you;</li>
                            <li className="leading-loose text-textPara mb-3">We believe that we need to share information about you to provide a service that you have requested from us or from your provider;</li>
                            <li className="leading-loose text-textPara mb-3">We are complying with laws or responding to lawful requests and legal process or responding in an emergency situation;</li>
                            <li className="leading-loose text-textPara mb-3">We believe it is necessary to protect our rights and the security of our Platform, or the rights of our customers or partners, or to avoid liability or violations of the law; or</li>
                            <li className="leading-loose text-textPara mb-3">We may also disclose your information in connection with or during negotiation of any merger, financing, acquisition or bankruptcy transaction or proceeding involving sale or transfer of all or a portion of our business or assets to another company.</li>
                        </ul>
                        <p className="leading-loose text-textPara mb-3">In addition when sharing certain information protected by HIPAA,</p>
                        <ul className="pl-5 list-disc">
                            <li className="leading-loose text-textPara mb-3">We share your information with our HIPAA Covered Entity clients who provide you with services, including medical groups,
                                practices, hospitals, health systems, and physicians, specialists and staff;</li>
                            <li className="leading-loose text-textPara mb-3">We share your information with third parties that you consent to or direct us to send/receive information to/from;</li>
                            <li className="leading-loose text-textPara mb-3">We may share your information with our third party vendors, consultants, agents, and other service providers with whom we contract as a Business Associates under HIPAA to help us provide or improve the Platform. For example, we may work with companies to host and maintain our data, website or mobile application properties, analyze our data or provide marketing assistance;</li>
                            <li className="leading-loose text-textPara mb-3">We may disclose your health information to third party vendors in accordance with HIPAA requirements and your providers' internal privacy practices. For further details regarding these requirements, refer to the HIPAA Privacy Rule at 45 C.F.R. section 164.506;</li>
                        </ul>
                        <p className="leading-loose text-textPara mb-3">We may also share aggregated information (information about our users that we combine together so that it no longer identifies or references an individual user) and other anonymized information for regulatory compliance, industry and market analysis, research,
                            demographic profiling, marketing and advertising, and other business purposes. We may de-identify and aggregate your data, for business purposes in accordance with our agreements with our HIPAA Covered Entity Clients (healthcare providers);</p>
                        <div className="text-2xl mb-4 font-semibold">5. OTHER IMPORTANT INFORMATION</div>
                        <div className="text-xl mb-4 font-semibold">5.1 Analyzing your Communications.</div>
                        <p className="leading-loose text-textPara mb-3">We may review, scan, or analyze your communications on the VCarePro Platform for fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, analytics, and customer support purposes. These activities are carried out based on VCarePro's legitimate interest in ensuring compliance with applicable laws and our Terms, preventing fraud, promoting safety, and improving and ensuring the adequate performance of our services.</p>
                        <div className="text-xl mb-4 font-semibold">5.2 Linking Third Party Accounts.</div>
                        <p className="leading-loose text-textPara mb-3">You may link your VCarePro Account with your account at a third party social networking service and must comply with the Terms of those sites. Any information you disclose to those sites may be used by those sites. We only collect your information from linked third party accounts to the extent necessary to ensure the
                            adequate performance of our contract with you, or to ensure that we comply with applicable laws, or with your consent.</p>
                        <div className="text-xl mb-4 font-semibold">5.3 Third Party Partners & Integrations</div>
                        <p className="leading-loose text-textPara mb-3">The VCarePro Platform may contain links to third party websites or services, such as third party integrations, co-branded services, or third party-branded services (“Third Party Partners”). VCarePro doesn’t own or control these Third Party Partners and when you interact with them, you may be providing information directly to the Third Party Partner, VCarePro, or both. These Third Party Partners will have their own rules about the collection, use, and disclosure of information. We encourage you to review the privacy policies of the other websites you visit.</p>
                        <div className="text-2xl mb-4 font-semibold">6. YOUR RIGHTS</div>
                        <p className="leading-loose text-textPara mb-3">You may exercise any of the rights described in this section by sending an email to info@VCarePro.net. Please note that we may ask you to verify your identity and request before taking further action on your request.</p>
                        <div className="text-xl mb-4 font-semibold">6.1 Managing Your Information</div>
                        <p className="leading-loose text-textPara mb-3">You may access and update some of your information through your Account settings. If you have chosen to connect your VCarePro Account to a third-party application you can change your settings and remove permission for the app by changing
                            your Account settings. You are responsible for keeping your personal information up-to-date.</p>
                        <div className="text-xl mb-4 font-semibold">6.2 Rectification of Inaccurate or Incomplete Information</div>
                        <p className="leading-loose text-textPara mb-3">You have the right to ask us to correct inaccurate or incomplete personal information about you (and which you cannot update yourself within your VCarePro Account).</p>
                        <div className="text-xl mb-4 font-semibold">6.3 Data Access and Portability</div>
                        <p className="leading-loose text-textPara mb-3">In some jurisdictions, applicable law may entitle you to request copies of your personal information held by us. You may also be entitled to request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format and/or request us to transmit this information to another service provider (where technically feasible).</p>
                        <ul className="pl-5 list-disc">
                            <li className="leading-loose text-textPara mb-3">Data Retention and Erasure. We generally retain your personal information for as long as is necessary for the performance of the contract between you and us and to comply with our legal obligations. In certain jurisdictions, you can request to have all your personal information deleted entirely." Please note that if you request the erasure of your personal information, we may retain some of your personal information as necessary for our legitimate business interests, such as fraud detection and prevention and
                                enhancing safety. For example, if we suspend a VCarePro Account for fraud or safety reasons, we may retain certain information from that VCarePro Account to prevent that User from opening a new VCarePro Account in the future; we may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, VCarePro may keep some of your information for tax, legal reporting and auditing obligations and because we maintain the VCarePro Platform to protect from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time.</li>
                        </ul>
                        <div className="text-xl mb-4 font-semibold">6.5 Withdrawing Consent and Restriction of Processing</div>
                        <p className="leading-loose text-textPara mb-3">If we are processing your personal information based on your consent you may withdraw your consent at any time by changing your Account settings or by sending a communication to VCarePro specifying which consent you are withdrawing. Please note that the withdrawal of your consent does not affect the lawfulness of any processing activities based on such consent before its withdrawal. Additionally, in some jurisdictions, applicable law may give you the right to limit the ways in which we use your personal information, in particular where (i) you contest the accuracy of your personal information; (ii) the processing is unlawful
                            and you oppose the erasure of your personal information; (iii) we no longer need your personal information for the purposes of the processing, but you require the information for the establishment, exercise or defense of legal claims; or (iv) you have objected to the processing pursuant to Section 6.6 and pending the verification whether the legitimate grounds of VCarePro override your own.</p>
                        <div className="text-xl mb-4 font-semibold">6.6 Objection to Processing</div>
                        <p className="leading-loose text-textPara mb-3">In some jurisdictions, applicable law may entitle you to require VCarePro not to process your personal information for certain specific purposes (including profiling) where such processing is based on legitimate interest. If you object to such processing VCarePro and/or VCarePro Payments will no longer process your personal information for these purposes unless we can demonstrate compelling legitimate grounds for such processing, or such processing is required for the establishment, exercise or defense of legal claims. Where your personal information is processed for direct marketing purposes, you may, at any time ask VCarePro to cease processing your data for these direct marketing purposes by sending an e-mail to info@VCarePro.net.</p>
                        <div className="text-xl mb-4 font-semibold">6.7 Lodging Complaints</div>
                        <p className="leading-loose text-textPara mb-3">You have the right to lodge complaints about our data processing activities by filing a complaint with our Data
                            Protection Officer who can be reached by the “Contact Us” section below or with a supervisory authority.</p>
                        <div className="text-2xl mb-4 font-semibold">7. OPERATING TRANSFERS</div>
                        <p className="leading-loose text-textPara mb-3">To facilitate our operations VCarePro may transfer, store, and process your information within our family of companies, partners, and service providers. Laws may differ from the laws in your residence.</p>
                        <div className="text-xl mb-4 font-semibold">7.1. California & Vermont Residents</div>
                        <p className="leading-loose text-textPara mb-3">VCarePro Payments will not share information it collects about you with its affiliates or third parties (both financial and non-financial), except as required or permitted by your state’s law.</p>
                        <div className="text-xl mb-4 font-semibold">7.2. California Privacy Rights</div>

                        <p className="leading-loose text-textPara mb-3">California law permits Users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those third parties. See the “Contact Us” section for where to send such requests. VCarePro do not share personal information with third parties for their own direct marketing purposes without your prior consent. Accordingly
                            s for their direct marketing purposes by withholding consent.</p>
                        <p className="leading-loose text-textPara mb-3">As described above, certain information we collect through the Platform is HIPAA protected health information or otherwise covered by the California Confidentiality of Medical Information Act. Therefore, our practices with respect to the Platform are exempt from the California Consumer Privacy Act (the CCPA).</p>
                        <div className="text-2xl mb-4 font-semibold">8. SECURITY</div>
                        <p className="leading-loose text-textPara mb-3">We are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration. Some of the safeguards we use to protect your information are firewalls and data encryption, and information access controls. If you know or have reason to believe that your VCarePro Account credentials have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorized use of your VCarePro Account, please contact us following the instructions in the Contact Us section below. These safeguards are intended to secure our system and meet our obligations under the HIPAA Security Standards</p>
                        <div className="text-2xl mb-4 font-semibold">9. CHANGES TO THIS PRIVACY POLICY</div>
                        <p className="leading-loose text-textPara mb-3">VCarePro reserves the right to modify this Privacy Policy at any time in accordance with this provision. If we make changes to this Privacy Policy, we will post the revised Privacy Policy on the VCarePro Platform and update the “Last Updated” date at the top of this Privacy Policy. We will do our best to notify you (with the updated terms being displayed on-screen or with a link to the updated terms). If you disagree with the revised Privacy Policy, you may cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the VCarePro Platform will be subject to the revised Privacy Policy</p>
                        <div className="text-2xl mb-4 font-semibold">10. CONTACT US</div>
                        <p className="leading-loose text-textPara mb-3">If you have any questions or complaints about this Privacy Policy or VCarePro’s information handling practices, you may email us at the email addresses provided in</p>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Policy